.quickpay-Container {
  margin: 60px 80px;
  border-radius: 25px;
  display: flex;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
}

.quick-pay-left-cart {
  width: 45%;
  padding: 50px;
  background-color: #0c0dcb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.gift-card-left-cart {
  width: 45%;
  padding: 50px;
  background-color: #F3A320;
  background-image: linear-gradient(#0c0dcb, #F3A320);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.quick-pay-right-field {
  width: 55%;
  padding: 50px;
  text-align: left;
}

.Quick-pay-fields {
  width: 100%;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: none;
  padding-left: 10px;
  font-size: 15px;
  color: rgb(67, 67, 67);
}

.Quick-pay-field-heading {
  font-size: 20px;
  font-family: var(--poppins-family);
}

.Quick-pay-fields>textarea {
  height: 60px;
}

.Quick-pay-fields::placeholder {
  font-size: 12px;
  color: #cecece;
  font-family: var(--poppins-family);
}

.Quick-pay-fields:focus {
  border: 1px solid #feae01;
}

.label-name-container {
  position: absolute;
  background-color: #ffffff;
  height: 1px;
  top: 0;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Quick-pay-label {
  font-size: 10px;
  padding: 0 3px;
  font-weight: 600;
  font-family: var(--poppins-family);
  /* z-index: 1; */
  background: white;
}

.quick-pay-container {
  position: relative;
  margin: 20px 0;
  margin-bottom: 0;
}

.quick-pay-outer-container {
  background-color: #f5f5f5;
}

.Select-option {
  height: 38px;
  color: #7e7b7b;
  font-size: 14px;
}

.termsAndConditions {
  font-size: 9px;
  color: rgb(140, 140, 140);
  font-family: var(--poppins-family);
  font-weight: 500;
}

.just-padel-Name {
  font-size: 30px;
  color: #fff;
  font-weight: 500;
}

.termsAndConditions>a {
  font-size: 9px;
  color: rgb(140, 140, 140);
  font-family: var(--poppins-family);
}

.Quick-pay-buttons-save {
  width: 100%;
  background-color: #feae01;
  color: #fff;
  margin-top: 20px;
  border: 0;
  outline: none;
  border-radius: 4px;
  font-family: var(--poppins-family);
}

.Quick-pay-buttons-save:disabled {
  cursor: not-allowed;
  background-color: #7e7b7b;
}

.image-box {
  background-color: #b98514;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quick-box-justPadelBlue {
  display: flex;
  align-items: center;
}

.quick-box-justPadelBlue>.text-headline {
  color: #fff;
  margin-left: 8px;
  font-size: 15px;
  font-family: var(--poppins-family);
}

.quick-pay-justPadelBlue {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.quick-pay {
  color: #fff;
  font-size: 32px;
  margin-top: 50px;
  font-weight: 500;
  font-family: var(--poppins-family);
}

.quick-pay-sef {
  color: #fff;
  font-family: var(--poppins-family);
  font-size: 15px;
}

.quick-box-justPadelWhite {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.just-padel-court-Name {
  color: rgb(208, 208, 208);
  margin-top: 5px;
  font-family: var(--poppins-family);
  font-size: 14px;
}

.css-b62m3t-container {
  width: 100%;
  background: none;
  border: none;
  /* height: 55px; */
}

.css-319lph-ValueContainer {
  height: 30px;
  /* margin-top: 20px; */
}

.css-6j8wv5-Input {
  height: 30px;
  margin-top: 30px;
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 0 #fdae00 !important;
  border-color: #fdae00 !important;
  box-shadow: none;
}

.gift-card-conatiner{
  width: 45%;
  background-color: #F3A320;
  background-image: linear-gradient(#0c0dcb, #F3A320);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 990px) {
  .quickpay-Container {
    margin: 60px 50px;
  }

  .quickpay-Container {
    flex-direction: column;
  }

  .quick-pay-left-cart {
    width: 100%;
  }

  .quick-pay-right-field {
    width: 100%;
  }

  .quick-box-justPadelWhite {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .quickpay-Container {
    margin: 60px 0;
    border-radius: 0;
  }

  .quick-pay-justPadelBlue {
    justify-content: center;
    align-items: center;
  }
  .gift-card-image {
    height: 300px;
    width: 330px;
  }
  .gift-card-conatiner{
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .quickpay-Container {
    border-radius: 0%;
  }

  .quick-pay-left-cart {
    padding: 50px 25px;
  }

  .quick-pay-right-field {
    padding: 50px 25px;
  }

  .gift-card-left-cart {
    width: 100%;
    height: 320px;
  }

  .gift-card-image {
    height: 275px;
  }

  .quick-box-justPadelWhite {
    margin-top: 0px;
  }

  .gift-card-image {
    height: 300px;
    width: 330px;
  }
  .gift-card-conatiner{
    width: 100%;
  }
}


@media screen and (max-width: 320px) {
  .quickpay-Container {
    border-radius: 0%;
  }

  .quick-pay-left-cart {
    padding: 50px 25px;
  }

  .quick-pay-right-field {
    padding: 50px 25px;
  }

  .gift-card-left-cart {
    width: 100%;
    height: 320px;
  }

  .gift-card-image {
    height: 275px;
    width: 300px;
  }

  .quick-box-justPadelWhite {
    margin-top: 0px;
  }
  .gift-card-conatiner{
    width: 100%;
  }
}