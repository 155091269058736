@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap");
:root {
  --alumni-family: "Alumni Sans", sans-serif;
  --poppins-family: "Poppins", sans-serif;
}

.historyCourtContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.historyCourtContainer thead {
  width: 100%;
  display: flex;
  justify-content: center;
}
.historyCourtContainer th {
  width: 70%;
  display: flex;
  justify-content: center;
}
.historyCourtContainer tbody,
.historyCourtContainer td {
  width: 100%;
  display: flex;
  justify-content: center;
}
.HistorycourtDetails {
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 20px;
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.court-image-container {
  display: flex;
}
.bookingStatus {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.bookingStatus > .TotalAmount {
  color: rgb(108, 108, 108);
  font-size: 13px;
  margin-left: 34px;
  font-weight: 400;
}
.bookingStatusIcon {
  margin-right: 10px;
}
.bookingStatusText {
  font-family: var(--poppins-family);
  font-size: 14px;
  margin-right: 25px;
}
.btn-and-paymentStatus {
  display: flex;
  align-items: center;
  height: auto;
}
.show-modal-btn {
  background-color: #fdae00;
}
.court-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}
.court-name-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
}
.court-name-details > h4 {
  font-size: 25px;
  font-family: var(--poppins-family);
  margin: 0;
  text-align: left;
}
.court-name-details > h5 {
  font-family: var(--poppins-family);
  font-size: 14px;
  margin-top: 5px;
}
.court-image > img {
  width: 200px;
  border-radius: 4px;
}
.bookedSlots {
  font-family: var(--poppins-family);
  color: #686868;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.bookingText {
  font-family: var(--poppins-family);
  font-size: 20px;
  margin-top: 20px;
}
.history-footer {
  /* margin-top: 80px; */
}
.inner-table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.childgreeting {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .court-name {
    flex-direction: column;
  }
  .btn-and-paymentStatus{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .court-image-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .court-name-details {
    padding: 0 0 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .court-name {
    flex-wrap: wrap;
    display: flex;
    justify-content: end;
  }
  .court-image-container {
    width: 100%;
  }
 
 
}
@media screen and (max-width: 575px) {
  .court-image > img {
    width: 100%;
  }
  .court-image {
    padding-right: 0px;
  }
  .court-name-details {
    margin-top: 15px;
    padding: 0;
  }
  .btn-and-paymentStatus {
    margin-top: 15px;
    flex-direction: column;
    display: block;
  }
  
  .court-image-container {
    flex-wrap: wrap;
  }
  .bookingStatus{
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .court-name-details > h4{
    text-align: left;
    font-size: 20px;
  }
  
}
@media screen and (max-width: 426px) {
  .historyCourtContainer tbody,
  .historyCourtContainer td {
    width: 100%;
  }
  .HistorycourtDetails {
    margin-bottom: 0;
    width: 90%;
  }
  .historyCourtContainer th {
    width: 85%;
  }
  .court-name {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 375px) {
  .UserGreetingContainer-rhs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: 2.15 !important;
  }
  .HistorycourtDetails {
    padding: 15px 15px 40px 15px;
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 20px;
    position: relative;
    width: 100%;
    margin-top: 20px;
  }
  .bookNow-btn {
    padding: 0px 10px;
  }
  .bookNow-btn {
    padding: 7px;
    font-size: 12px;
  }
  .greetingName {
    font-family: var(--poppins-family);
    font-size: 22px;
    text-transform: capitalize;
  }
}
