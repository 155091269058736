.UserGreetingContainer {
    display: inherit;
    width: 70%;
    padding: 15px 0;
    margin-top: 30px;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 24px;
    max-width: 1140px;
  }
  .UserGreetingMainContainerlist {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .UserGreetingContainer>div {
    flex-grow: 1;
  }

  .UserGreetingContainer-lhs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .UserGreetingContainer-rhs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .UserGreetingContainer-btn {
    border: 0;
    outline: 0;
    padding: 0px 10px;
    margin-left: 10px;
    border-radius: 4px;
    font-family: var(--poppins-family);
    color: black;
    background-color: rgb(232, 232, 232);
  }
  .greetingName {
    font-family: var(--poppins-family);
    font-size: 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  
  }
  .greetingEmail {
    font-family: var(--poppins-family);
    font-size: 15px;
    font-weight: bold;
  }

  .UserGreetingMainContainerhistory {
    display: flex;
    width: 100%;
    justify-content: center;
    /* padding: 0px 228px; */
  }


  @media (max-width: 768px) {
    .UserGreetingContainer {
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        padding: 20px 0;
      }
    
      .UserGreetingContainer>div {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        gap: 10px;
      }
      .UserGreetingContainer-btn{
          margin-left: 0;
      }
      /* .UserGreetingContainer-rhs{
          width: 100%;
          display: flex;
          justify-content: space-between;
      } */
  }
  @media (max-width: 575px) {
  .UserGreetingContainer-btn {
    width: 105px;
    font-size: 13px;
  }

  }
  @media (max-width: 426px) {
    .UserGreetingMainContainer {
        padding: 0px 28px;
        margin: 0;
        
      }
    .UserGreetingContainer>div {
        display: flex;
        justify-content: center;
      }
    
      .UserGreetingContainer-btn {
        width: 90px;
        font-size: 11px;
        margin-top: 15px;
      }
    
      .UserGreetingContainer>div {
        padding: 0;
      }
      .UserGreetingContainer {
        width: 95%;
      }
      .greetingName {
        font-size: 20px;
      }
      .greetingEmail {
        font-size: 12px;
        font-weight: normal;
        text-align: center;
      }
  }

  @media (max-width: 376px) {
  
  
    .UserGreetingMainContainer {
      padding: 0px 29px;
    }
  }
  
  @media (max-width: 320px) {
    .UserGreetingMainContainer {
      padding: 0px 26px;
    }
  
 
  }