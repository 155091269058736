.Thankyou-page-container {
    width: 100%;
    padding: 10px;
    display: flex;
}

.Thankyou-page-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}

.Thankyou-page-Right {
    width: 50%;
    text-align: right;
}

.top-date-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.Thankyou-page-colorTable-th {
    margin-top: 30px;
}

.Thankyou-page-table table,
.Thankyou-page-table th,
.Thankyou-page-table td {
    border: 1px solid rgb(155, 154, 154);
    border-collapse: collapse;
    padding: 5px;
    text-align: left;
}

.top-header-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
}

.Thankyou-page-colorTable table,
.Thankyou-page-colorTable th,
.Thankyou-page-colorTable td {
    border: 2px solid white;
    padding: 5px;
    background-color: rgb(228, 228, 228);
    text-align: left;
}

.Thankyou-page-colorTable-th {
    border-bottom: 2px solid rgb(70, 70, 70);
}

.thanks-greeting {
    font-size: 13px;
}