@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root{
    --alumni-family: 'Alumni Sans', sans-serif;
    --poppins-family: 'Poppins', sans-serif;
}
.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
}
.img-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -86px;
    max-height: 470px;
    overflow: hidden;
    
}
.banner {
    width: 100% ;
    justify-content: center;
    align-items: center;
    display: flex;
    max-height: 470px;
    
}

.background-overlay {
    width: 100%;
    background-color: #000;
    opacity: 0.66;
    position: absolute;
    top: 0px;
    height: 100%;
}

.padel-title {
    position: absolute;
    margin-top: 100px;
}

.padel-title h1 {
    color: #fff;
    font-family: var(--alumni-family);
    font-size: 90px;
    letter-spacing: 2px;
    font-weight: 600;
}

.booking-containers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.3rem;
    margin-top: 22px;
    width: 70%;
    max-width: 1140px;
}
.booking-containers .courts {
    border: 1px solid #D9D9D9;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
}
.padelImg{
    width: 100%;
    height: 211px;
    object-fit: cover;
}
.court-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}
.court-details {
    width: 90%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    position: relative;
    height: 325px;
}

.court-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.court-details h4 {
    font-family: var(--poppins-family);
    font-size: 19px;
    margin: 5px 0;
    color: #232240;
    text-align: left;
}

.court-count {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.court-count h5 {
    margin: 0;
    font-family: var(--poppins-family);
    font-weight: 400;
    font-size: 16px;
    color: #232240;
    text-align: left;
}

.court-icons {
    display: flex;
}

.faPhone {
    color: #0000FF;
    margin-right: 20px;
}

.faPaperPlane {
    color: #FDAF2B;
    margin-right: 15px;
}

.court-details h4 {
    font-family: var(--poppins-family);
    font-size: 19px;
}

.court-time {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.court-time-second {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.court-time h5 {
    margin: 0px 0px;
    color: #232240;
    font-size: 15px;
    font-family: var(--poppins-family);
    font-weight: 400;
    text-align: left;
    display: flex;
    flex-direction: row;
    padding-left: 3px;
    justify-content: space-between;
}

.space-time{
margin-right: 10px;
}

.court-time-second h5 {
    margin: 0px 5px;
    color: #232240;
    font-size: 16px;
    font-family: var(--poppins-family);
    font-weight: 400;
}

.faClock {
    color: #232240;
    margin-right: 8px;
}
.court-has {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
    flex-wrap: wrap;
}
.court-has-time {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 25px 0px 10px 0px;
}
.court-has-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
}
.court-has-time small {
    padding: 5px 15px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 10px;
    font-family: var(--poppins-family);
}

.court-has small {
    padding: 3px 5px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 7px;
    font-family: var(--poppins-family);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
}
.court-has-title small {
    padding: 5px 15px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 10px;
    font-family: var(--poppins-family);
}
.book-btn {
    width: 100%;
    background-color: #FDAE00;
    color: #fff;
    border: 0;
    border-radius: 4px;
    height: 45px;
    margin-top: 15px;
    font-family: var(--poppins-family);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
}
.book-btn h5 {
    color: #fff;
    font-family: var(--poppins-family);
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}
.book-btn:hover h5 {
    color: #FDAE00;
    transition: all .3s;
}
.book-btn:hover {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #FDAE00;
    transition: all .3s;
}

.elementor-heading-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    width: 84%;
    max-width: 1140px;
}
.elementor-heading-title h1{
    font-size: 1.5rem;
    color: #232240;
    font-weight: 500;
    line-height: 1;
    
}
.facilities-img-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 40px 0;
    flex-wrap: wrap;
    
    
}
.facilities-offers-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 30px 0;
    flex-wrap: wrap;
    
}

.brands-img-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 40px 0;
    flex-wrap: wrap;
    gap: 100px;
}
.img-const{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    width: 140px;
    margin-top: 15px;
}
.img-offers{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 520px;
    width: 140px;
    margin-top: 15px;
}

.img-offers h4{
    color: #636161;
    letter-spacing: 0.3px;
    font-size: 16px;
    text-align: center;
    margin-top: 2px;
}
.img-offers strong{
    letter-spacing: 0.3px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}
.img-const h4{
    color: #636161;
    letter-spacing: 0.3px;
    font-size: 16px;
    text-align: center;
    margin-top: 2px;
}
.img-const strong{
    letter-spacing: 0.3px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}
.facilities-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.GreetingOuterContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -86px;
}
.bookNowGreeting{
    width: 82% !important;
}
.spacing{margin-top: 400px;}

@media(max-width: 2560px){
    .facilities-offers-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    .img-offers{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 170px;
        width: 140px;
        margin-top: 45px;

    }
}
@media(max-width: 1680px){
    .facilities-offers-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    .img-offers{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 170px;
        width: 140px;
        margin-top: 45px;

    }
}

@media(min-width: 1024px) and (max-width:1440px){
    .court-details{
        height: 335px;
    }
    .UserGreetingMainContainer {
        padding: 0px 109px;
        width: 80% !important;
      }
      .booking-containers{
          width: 81%;
      }
      .facilities-offers-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    .img-offers{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 170px;
        width: 140px;
        margin-top: 45px;

    }
}
@media(max-width: 991px){
    .booking-containers {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media(min-width: 768px) and (max-width:1024px){
    .court-details{
        height: 420px;
    }
    .facilities-offers-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin: 0 0 40px 0;
        flex-wrap: wrap;
        gap:9px;
        
    }
    .img-offers{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 140px;
        width: 140px;
        margin-top: 70px;
    }
   
}

@media(max-width: 575px){
    .booking-containers {
        grid-template-columns: repeat(1, 1fr);
    }
    .padelImg{
        height: auto;
    }
}
@media(max-width: 425px){
    .court-details{
        height: 320px;
    }
    .elementor-heading-title h1 {
        font-size: 1rem;
        text-align: center;
    }
    .facilities-offers-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin: 0 0 60px 0;
        flex-wrap: wrap;
        gap:1px;
        
    }
    .img-offers{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 130px;
        width: 140px;
        margin-top: 70px;
    }
}
@media(min-width: 375px) and (max-width:767px){
    .court-details {
        height: 325px;
    }
    
    .img-container{
        overflow: hidden;
    }
    .banner {
        width: auto;
    }
}
@media(min-width: 320px) and (max-width:767px){
    .court-details {
        height: 335px;
    }
    .booking-containers{
        width: 96%;
    }
}


