@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&display=swap');


:root {
    --alumni-family: 'Alumni Sans', sans-serif; ;
    --white: #ffffff;
  }
.nav-components{
    background-color: #28262699;;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1000;
    padding: 42px 0px 45px 0px;
}
.nav-inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1140px;
}

.img-logo{
    width: 250px;
    height: auto;
}
.Navbar{
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}
.Navbar ul li{
    list-style: none;
    display: inline-block;
    padding: 5px 15px;
    text-decoration: none;
    color: aquamarine;
}
.Navbar ul li .navName{
    text-decoration: none;
    color: rgba(255,255,255,0.5);
    font-size: 17px;
    font-weight: 400;
    font-family: sans-serif;
}
.Navbar ul li .navName:hover{
    color: rgb(255, 255, 255);
}
.active{
    color: rgb(255, 255, 255) !important;
}
.nav-btn-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bookNow-btn{
    color:rgb(202, 202, 202);
    text-decoration: none;
    border: 1.5px solid rgb(202, 202, 202);
    padding: 6px 25px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
.bookNow-btn:hover{
    background-color: #0318E3;
    transition: all .5s;
    color:rgb(255, 255, 255);
    border: 1.5px solid rgb(255, 255, 255);
}
.login-btn{
    color:#ffc342;
    text-decoration: none;
    padding: 6px 25px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
.login-btn:hover{
    color:#FDAE00;
}
.nav-btn-container .bar{
    display: none;
}
#click{
    display: none;
}
.logout{
    margin-left: 20px;
    color:rgb(202, 202, 202);
    text-decoration: none;
    border: 1.5px solid rgb(202, 202, 202);
    padding: 0px 20px;
    border-radius: 7px;
    font-size: 15px;
    background-color: transparent;
}
.logout:hover{
    background-color: #FDAE00;
    transition: all .5s;
    color:rgb(255, 255, 255);
    border: 1.5px solid rgb(255, 255, 255);
}
@media(max-width: 1680px){
    
}
@media(max-width: 1199px){
    .bookNow-btn {
        padding: 3px 20px;
    }
    .Navbar ul li {
        padding: 5px 14px;
    }
    .logo-container img{
        width: 200px;
    }
}
@media(max-width: 991px){
    .Navbar ul{
        position: fixed;
        top: 70px;
        left:0;
        right: 0;
        height: 100vh;
        width: 100%;
        transition: transform 5s ease;
        background-color: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        display: none;
    }
    .Navbar ul li {
        padding: 25px 15px;
    }
    .Navbar ul li .navName {
        font-size: 20px;
    }
    .nav-btn-container .bar{
        margin-left: 30px;
    }
    .bar .line{
        width: 35px;
        height: 3px;
        display: block;
        background-color: white;
        margin: 6px auto;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        position: relative;
        transition: transform .3s;
    }
    #click:checked ~ .bar .line:nth-child(1){
        transform: rotate(45deg);
        top: 10px;
    }
    #click:checked ~ .bar .line:nth-child(2){
        opacity: 0;
        transform: scale(1.2);
    }
    #click:checked ~ .bar .line:nth-child(3){
        transform: rotate(-45deg);
        top: -8px;
    }
}

@media (max-width: 426px)
{
.logo-container img {
    width: 150px;
}}

@media (max-width: 376px){
.bookNow-btn{
    font-size: 14px;
}
}

