.input-without-border-radius{
    width: 100%;
}
.reset .account__card{
    width:800px;
}

.reset .account__btn-reset{
    width: 100%;
    height: 40px;
    padding: 0px;
}
.reset .input-without-border-radius{
    padding:0px ;
    padding-left: 5px;
    height: 35px;
    border-radius: 4px;

}

.reset .reset-input{
    width: 90%;
     font-size:14px;
     padding-left:8px;
     border: none !important;
     
}

.eye-input{
    border-left:1px solid #8080801c;
    padding:5px 14px 8px 10px;
}

.reset .form__form-group-button{
    border: none;
    width: 30px;
    border-radius: 4px;
}
.reset .form__form-group-label{
    color: gray;
}
.calender-loader {
    color: #4ce1b6;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
}
.password-border{
    border: 1px solid #f2f4f7;
     color: #646777;
     margin-top: 8px
}

.reset-icon-font{
    font-size: 14px;
}

