.input-feilds input[type=radio]{
 height: auto;
 width: auto;
 margin: 2px 10px 0px 0px;
}
.input-feilds input[type=radio]:nth-child(2){
 margin-left: 15px;
}
.genderContainer{
    display: flex;
    align-items: center;
    width: 95%;
    height: 40px;
}
.profileUpload{
    width: 88px;
    height: 88px;
    position: relative;
}
.ProfileImageContainer{
    border: 3px solid #FDAE00;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    
}
.ProfileImage{
    width: 88px;
    height: 88px;
    object-fit: cover;
}
.uploadContainer{
    position: absolute;
    border: 2px solid #777777;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 3px;
    right: 0;
    bottom: 0;
    background-color: #fff;
    cursor: pointer;
}
.uploadCameraIcon{
    color: #777777;
}
.greetingProfileImageContainer{
    border: 2px solid #FDAE00;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
}
.greetingProfileImage{
    width: 35px;
    height: 35px;
    object-fit: cover;
}
.save-Btn{
    background-color: #FDAE00;
    color: #fff;
    padding: 5px 10px;
    border: 0;
    outline: 0;
    border-radius: 2px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    width: 100%;
}
@media (max-width: 991px) {
    .save-Btn{
        width: 90%;
    }
}