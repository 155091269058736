.calender-Container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  align-items: center;
  margin-top: 0px;
  position: relative;
}

.inner-calender {
  width: 85%;
  border-radius: 10px;
  background-color: #fafad2;
  padding: 20px;
  border: 2px solid #fdae00;
  display: flex;
  flex-direction: row;
}

.inner-lhs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.inner-rhs {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.feild-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 15px;
}

.feild-detail input {
  width: 95%;
  height: 25px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.feild-detail input:focus {
  border: 1px solid #fdae00;
}

.form-label {
  font-family: var(--poppins-family);
}

.textarea-rhs {
  width: 100%;
}

.textarea {
  width: 95%;
}

.submit-btns {
  width: 95%;
  margin-top: 15px;
  margin-right: 15px;
}

.book-court {
  margin-left: -150;
  margin-top: 80;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .book-court {
    margin-left: -150;
    margin-top: 80;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inner-lhs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .book-court {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 375px) and (max-width: 413px) {
  .inner-lhs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
}

