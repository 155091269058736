.toggle-switch {
    position: relative;
    width: 40px;
    display: inline-block;
    text-align: left;
    border-radius: 20px;
  }
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height:20px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "";
    padding-left: 10px;
    background: linear-gradient(#a5f3a7, #03CD00) ;
    color: #fff;
  }
  .inner:after {
    content: "";
    padding-right: 10px;
    background: linear-gradient(#d3d3d3, #8f8f8f) ;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 16px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border: 0 solid #bbb;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }