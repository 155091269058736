* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.appshare_container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
}

.inner-container {
    height: 400px;
    width: 60rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.inner-container h2 {
    font-family: sans-serif;
    color: #fff;
}

.inner-container h3 {
    font-family: sans-serif;
    color: #fff;
    text-align: center;
}

.inner-container h4 {
    font-family: sans-serif;
    color: #fff;
    font-style: italic;
    margin-top: 20px;
}

.png-container {
    display: flex;
    margin-top: 50px;
}

.png-container img {
    width: auto;
}

@media (max-width:425px) {
    .png-container {
        flex-direction: column;
    }
}

@media(max-width:320px) {
    .inner-container h2 {
        text-align: center;
        font-size: 1.3rem;
    }
}