/* .App {
    text-align: center;
} */

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.form-edit {
    padding: 50px 300px;
}

.form-group {
    width: 100%;
    align-items: center;
    margin-bottom: 18px;
    text-align: start;
}

.form-field input {
    width: 650px;
    border-radius: 5px;
    border: 1px solid rgb(156, 156, 156);
}

.form-label {
    width: 100px;
    font-family: 'Trebuchet MS', sans-serif;
    text-align: initial;
}

.justpadel-buttons-save {
    height: 35px;
    background-color: #FDAE00;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: pointer;
    border: none;
    margin-right: 20px;
}

.justpadel-buttons-cancel {
    height: 35px;
    background-color: #cccccc;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: pointer;
}

.card {
    border: 0;
}

.col-buttons {
    width: 61%;
}

.text-danger {
    text-align: initial;
    width: 100%;
    font-size: 11px
}


.forget-password {
    width: 100%;
    /* margin-left: 29%; */
    /* position: fixed; */
    font-family: var(--poppins-family);
    font-size: 14px;
    color: #FDAE00;
    text-align: end;
    text-decoration: none;
}

.btn-warning:focus{
color: white;
background-color:none;
border-color: none;
box-shadow: none;

}

.forget-password:focus{
    color: #fff;
} 

.forget-password:hover{
    color: #fdae00;
} 
.error-msg {
    color: #b60606;
    font-size: 11px;
}

.account {
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: auto;
    background-color: #f2f4f7;
}


.account__wrapper {
    margin: auto;
    padding: 10px;
}

.account__card {
    background-color: #ffffff;
    padding: 50px 60px;
    max-width: 520px;
    width: 100%;
}

.theme-light .account__card {
    background-color: white;
}

.theme-dark .account__card {
    background-color: #232329;
}

.account.account--photo .account__title {
    color: #ffffff;
}

.account__logo {
    font-weight: 700;
}

.account__logo-accent {
    color: #FDAE00;
}

.account.account--photo .form__form-group-icon,
.account.account--photo .form__form-group-button {
    background: transparent;
}

.account.account--photo .form__form-group-icon,
.account.account--photo input:not(:last-child) {
    border-right: none;
}

.account.account--photo .form__form-group-button,
.account.account--photo input {
    border-left: none;
}

.account.account--photo .form__form-group-button.active {
    border-color: #eff1f5;
}

.account.account--photo .form__form-group-button.active svg {
    fill: #4ce1b6;
}

.account__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% + 10px);
    margin: -10px 0 -20px -10px;
}

.account__btns a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
}

.account__btn {
    width: 440px;
    margin-right: 0;
    padding: 0px;
}

/****************/
.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: white;
}

h3 {
    font-size: 18px;
    /* line-height: 32px; */
    font-family: Verdana, sans-serif;
    width: 285px;
}

h4.subhead {
    font-size: 12px;
    color: #999999;
    /* line-height: 16px; */
}

.account__head {
    margin-bottom: 30px;
    padding-left: 10px;
    text-align: left;
    border-left: 4px solid #FDAE00;
}

.theme-light h3,
.theme-light h4 {
    color: #646777;
}

/* .form__form-group-field {
    padding-left: 60px;
    padding-top: 40px;
} */


.account.account--photo {
    background: url(./components/ForgetPassword/img/404/bg_404.png) no-repeat center;
    background-size: cover;
}

.account.account--photo .account__card {
    background-color: rgba(0, 10, 16, 0.8);
    color: #ffffff;
}

.btn-warning:hover {
    color: #fff;
}

.input-without-border-radius {
    border: 1px solid #f2f4f7 !important;
    color: #646777
}

.input-without-border-radius:focus-visible {
    outline: none;
    border-color: orange !important;

}

/* .form input,
.form textarea {
    padding: 5px 10px;
    border-radius: 4px;
} */

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.error-msg {
    color: #b60606;
    font-size: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/************************Verify-Otp*****************************/
/*****************Validation-Email*****************/
.calender-loader {
    color: #4ce1b6;
    display: inline-block;
    width: 2rem;
    /* height: 2rem; */
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
    margin-left: 350px;
    margin-top: 50px;
    background-color: #ffffff;
    /* position: absolute; */

    /* background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: absolute;
    transition: all 1.3s;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    position: fixed; */
}
