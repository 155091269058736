
.booling__list-modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 17%);
    margin: 0px auto 0;
    transition: all .8s;
    border-radius: 2px;
    position: absolute;
    top: 1%;
    left: 200px;
    right: 200px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    max-width: 1140px;
    margin-top: 85px;
}
.inner_modal-body{
    width: 100%;
}
.toggleButtons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.toggle__body_content{
    width: 100%;
    height: 450px;
    overflow: hidden;
    overflow-y:scroll
}
.toggle__body_content::-webkit-scrollbar{
    width: 5px;
}
.toggle__body_content::-webkit-scrollbar-track {
    background: #ffffff; 
    border-radius: 5px;
  }
.toggle__body_content::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }
.toggle__body_content::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } 

.toggle__btn-active{
    background-color: #FDAE00;
    border: 2px solid #FDAE00 !important;
    color: #fff;
    width: 50%;
    margin: 3px;
    border: 0;
    outline: 0;
    border-radius: 2px;
    cursor: pointer;
}
.toggle__btn-diactive{
    background-color: transparent;
    color: #FDAE00;
    border: 2px solid #FDAE00 !important;
    width: 50%;
    margin: 3px;
    border: 0;
    outline: 0;
    border-radius: 2px;
    cursor: pointer;
    
}
.toggle__btn-diactive:hover{
    background-color: #ffefcc;
}

@media(max-width:1100px) {
    .booling__list-modal-wrapper {
        left: 120px;
        right: 120px;
    }
}
@media(max-width:1024px) {
    .booling__list-modal-wrapper {
        left: 100px;
        right: 100px;
    }
}
@media(max-width:768px) {
    .booling__list-modal-wrapper {
        left: 20px;
        right: 20px;
    }
}