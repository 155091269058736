.completedStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-textGray {
  color: rgb(133, 133, 133);
  font-size: 14px;
}
.payment-btn-container {
  margin: 25px 0 80px 0;
}
.payment-btn-container > Link {
  margin-right: 0;
}
.paymentLinkBtn {
  text-decoration: none;
  background: #efefef;
  padding: 10px;
  color: rgb(48, 48, 48);
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
}
.paymentLinkBtn:hover {
  color: rgb(48, 48, 48);
}
.paymenButtonBtn {
  text-decoration: none;
  background: #efefef;
  padding: 0 10px;
  color: rgb(48, 48, 48);
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 35px;
  outline: none;
  border: none;
}
.PaymentMainCourt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.childpayment {
  width: 100%;
}

.UserGreetingInnerContainer1 {
  width: 70%;
}
.UserGreetingContainer1 {
  display: flex;
  width: 100%;
  padding: 15px 0;
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 24px;
}
.thank-you-content{
  padding-top:80px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}


@media screen and (max-width: 376px) {
  .UserGreetingContainer1 {
    display: inherit;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    flex-wrap: wrap;
    margin: 24px auto 0;
  }
  .UserGreetingInnerContainer1 {
    width: 85%;
  }
}
