.modal-footer {
    height: auto;
    padding: 5px;
}

.modalBodyContent {
    font-family: var(--poppins-family);
    text-align: left;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-left: 20px;
}

.div-modal-book {
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    display: block;
    width: 100%;
}

.bookingid {
    color: rgb(11, 11, 71);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
}

.modal-content {
    border-radius: 12px;
    max-width: 400px;
    /* margin: 2rem auto; */
}

.addons {
    width: 100%;
    padding-top: 10px;
    font-weight: 600;
    font-size: 15px;
}

.text-bold {
    font-weight: 800;
    font-size: 15px;
    
}

.total-paid {
    padding-bottom: 15px;
    
}

.flex-end {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.spacing-modal{
    width: 200px;
    margin-bottom: 0px;
}
.space{
display: flex;

}

.input-due{
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;

}
.inp{
    margin-right: 0px;
}
.btn-clos{
background-color: transparent;
    height: 36px;
    width: 71px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.ModalBookingStatusContaioner{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.BookingStatusCanceledText{
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-radius: 4px;
    background-color: #f2f2f2 !important;
    color: red;
}
.BookingStatusSucessText{
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-radius: 4px;
    background-color: #f2f2f2 !important;
    color: green;
}


@media (max-width: 426px){
    .modal-content{
       max-width: 354px;
    }
    .modal-dialog{
        margin: 2rem auto;
    }
}
@media (max-width: 376px){
    .modal-content {
        max-width: 319px;
    }
}
@media (max-width: 320px){
    .modal-content {
        max-width: 300px;
        font-size: 15px;
    }
    .input-due{
       margin-left: 0px !important;
    }
    .spacing-modal {
        width: 176px;
        margin-bottom: 0px;
    }
}