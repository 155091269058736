.modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 17%);
    margin: 0px auto 0;
    transition: all .8s;
    border-radius: 2px;
    position: absolute;
    top: 1%;
    left: 240px;
    right: 240px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    max-width: 1320px;
    margin-top: 78px;
}

.inner-login {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
}

.sign-title h2 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 0px;
}

.modal-header {
    height: 50px;
    line-height: 20px;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;

}

.input-feilds {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.input-feilds input {
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 2px;
    border: 1px solid #e2e2e2;
    padding-left: 10px;
}

.input-feilds select {
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 2px;
    border: 1px solid #e2e2e2;
    padding-left: 10px;

}

.input-feilds .form-label {
    font-size: 12px;
    font-weight: 500;
}

.input-feilds input::placeholder {
    font-size: 13px;
    font-family: var(--poppins-family);
    color: #e2e2e2;
}

.modal-header h2 {
    float: left;
    margin: 0;
    padding: 0;
}

.modal-body {
    display: flex;
    justify-content: center;
    text-align: center;
    height: auto;
    padding: 30px;
}

.modal-footer {
    background: #e2e2e2;
    height: 35px;
    padding: 15px;
}

.close-modal-btn {
    cursor: pointer;
    font-size: 17px;
    margin: 0;
    font-weight: bold;
    font-family: var(--poppins-family);
}


.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-shed {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    transition: all 1.3s;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    position: fixed;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}

.validate-email-back-shed {
    display: block;
}

.back-shed::-webkit-scrollbar {
    width: 3px;
    border-radius: 3px;
}

.back-shed::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.back-shed::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.back-shed::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 3px;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
}

.width-left {
    width: 48%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.middle-name {
    height: 90%;
    background-color: #e2e2e2;
    width: 1px;
    outline: none;
    border: 0;
}

.width-right {
    width: 48%;
    justify-content: flex-start;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.Login-Registration-Form {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Login-Registration-Form .inner-calender {
    width: 400px;
    margin-right: 0;
}

.sub-btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.registre-msg {
    font-weight: 700px;
    color: green;
    margin-right: 20px;
}

.sub-btn .login {
    width: 100%;
    background-color: #FDAE00;
    color: white;
    font-family: var(--poppins-family);
    border: 0;
    height: 50;
    padding: 5px 0;
    font-size: 15px;
    border-radius: 2px;
    margin-top: 10px;
}

.loginGoogle {
    display: flex;
    justify-content: center !important;
}

.loginGoogle>span {
    font-size: 15px;
    font-family: Helvetica, sans-serif !important;
    font-weight: 700 !important;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s, border-color .3s;
}

.FbIcon {
    font-size: 25px;
    position: absolute;
    left: 10px;
    top: 7px;
}

.kep-login-facebook {
    position: relative;
    font-size: calc(.2vw + 12.71074px) !important;
    padding: 18px !important;
}

.sub-btngg {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.sub-btngg button {
    line-height: 1px !important;
    width: 95% !important;
}

.logingg {
    height: 43px;
    width: 441px;
    margin-right: 21px;
}

.sub-btnap,
.sub-btnfb {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.sub-btnap {
    width: 102%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.sub-btngg button {
    margin: 5px;
}

.sub-btnfb,
.sub-btngg span {
    width: 100%;
}

.sub-btnfb span button {
    width: 95%;
}

.sub-btnfb button {
    height: 43px;
    padding: 0px 10px 0px 0px;
    margin-left: -13px;
    margin-top: 21px;
}

.apple-auth-btn {
    height: 43px;
    margin-right: 0px;
}

.kep-login-facebook {
    border-radius: 4px !important;
    border: 0;
}

.div-or {
    font-size: 18px;
    margin-right: 26px;
    padding-top: 10px;
    font-weight: 400;
}

.sub-btn .register {
    width: 100%;
    background-color: #FDAE00;
    color: white;
    font-family: var(--poppins-family);
    border: 0;
    height: 50;
    padding: 5px 0;
    font-size: 15px;
    margin-bottom: 20px;
    border-radius: 2px;
}

.sign-title h2 {
    text-align: left;
    font-size: 17px !important;
    margin-bottom: 0px;
    margin-top: 20px;

}

.social-registration {
    margin-top: 25px;
    display: flex;
}

.social-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 15px;
    background-color: transparent;
    border: 0;
    outline: 0;
}

.social-icons {
    font-size: 30px;
}

.modal-title {
    margin: 0 auto;
    width: 100%;
}

.PhoneInput {
    width: 100%;
    border: 1px solid #e2e2e2 !important;
    padding-left: 10px;
}

.PhoneInputInput {
    border: 0 !important;
}

.email-icon {
    width: 10%;
    height: 35px;
    padding: 2px 6px 4px 11px;
    background: rgb(242, 244, 247);
    border: 1px solid #f2f4f7
}

.email-label {
    font-family: sans-serif;
    font-size: 15;
    color: #646777
}

.swal-button {
    padding: 0 15px;
}

@media(max-width:1100px) {
    .modal-wrapper {
        left: 120px;
        right: 120px;
    }
}

@media(max-width:991px) {
    .Login-Registration-Form .width-5 {
        width: 100%;
    }

    .Login-Registration-Form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .modal-wrapper {
        height: auto;
        padding-bottom: 50px;
    }

    .Login-Registration-Form .inner-calender {
        width: 400px;
        margin-right: 0;
    }

    .input-feilds input {
        width: 100%;
    }

    .sub-btn .login {
        width: 100%;
    }

    .sub-btn .register {
        width: 100%;
    }

    .sel-and-time {
        width: 100%;
    }

    .cart-container {
        width: 100%;
    }

    .width-left {
        width: 90%;
    }

    .width-right {
        width: 90%;
    }
}

@media(max-width:768px) {
    .Login-Registration-Form .inner-calender {
        width: 400px;
        margin-right: 0;
    }

    .modal-wrapper {
        left: 100px;
        right: 100px;
    }
}

@media(max-width:575px) {
    .Login-Registration-Form .inner-calender {
        width: 350px;
        margin-right: 0;
    }

    .Login-Registration-Form .submit-btns {
        width: 310px;
    }

    .modal-wrapper {
        left: 20px;
        right: 20px;
    }

    .modal-body {
        padding: 15px;
    }
}

@media(max-width:425px) {
    .Login-Registration-Form .inner-calender {
        width: 280px;
        margin-right: 0;
    }

    .Login-Registration-Form .submit-btns {
        width: 250px;
    }
}

@media(max-width:375px) {
    .loginGoogle>span {
        font-size: 12px;
    }

    .kep-login-facebook {
        font-size: 12px !important;
        padding-left: 10px;
    }
}

@media(max-width:320px) {
    .Login-Registration-Form .inner-calender {
        width: 250px;
        margin-right: 0;
    }

    .Login-Registration-Form .submit-btns {
        width: 210px;
    }
}