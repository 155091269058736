@font-face {
  font-family : Zuume;
  src: url(../../Assets/fonts/ZuumeMedium.ttf) format('truetype');
}
@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap");
:root {
  --alumni-family: "Alumni Sans", sans-serif;
  --poppins-family: "Poppins", sans-serif;
  
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fffbf1;
}
.inner-footer {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 70px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  max-width: 1320px;
}
.inner-footer .footer-details {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding-left: 50px;
  margin-top: 30px;
}
.PadelFooter {
  width: 110px;
}
.JustPadelName {
  width: 170px;
  margin-top: 15px;
}
.social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 25px;
  width: 14rem;
}
.social-icons a {
  text-decoration: none;
  color: #000;
}
.social-circle {
  border: 2px solid rgb(192, 192, 192);
  background-color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.inner-social-icons {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-circle:hover {
  border: 2px solid #fdae00;
  background-color: #fff;
  width: fixed;
  height: fixed;
  transition: 0.2s ease-in-out;
  margin: 0px;
}
.circleIcon {
  font-size: 18px;
}
.relate-link {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.relate-link h1 {
  font-family: Zuume;
  font-weight: 500;
  font-size: 40px;
  color: #232240;
  margin: 0;
  text-transform: uppercase;
}
.child-link-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.child-link-container .child-link {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.child-link-container .child-link h5 {
  margin: 20px 0 0 20px;
  font-family: var(--poppins-family);
  font-weight: 400;
  font-size: 17px;
  text-align: left;
}
.child-link-container .child-link h5 a {
  text-decoration: none;
  color: #000;
}
.footer-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.child-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.info-icons {
  border: 2px solid rgb(231, 231, 231);
  margin: 15px 5px 5px 0;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
}
.infoIcon {
  color: #fdae00;
}
.info-contact {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.child-info h5 {
  margin: 20px 0 0 12px;
  font-family: var(--poppins-family);
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  max-width: 300px;
}
.info-contact {
  margin-top: 20px;
}
.child-info h4 {
  margin: 0px 0 0 15px;
  font-family: var(--poppins-family);
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  max-width: 300px;
}
.footer-Line {
  width: 98%;
  color: #ccc;
  height: 1px;
  background-color: #ccc;
  border: 0;
}
.cpyright-container {
  width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  max-width: 1320px;
}
.cpyright-container div {
  width: 100%;
}
.inner-cpy-right {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.inner-cpy-right h5 {
  font-family: var(--poppins-family);
  font-weight: 400;
  font-size: 14px;
  color: #ccc;
}
.conditions {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.conditions h4 {
  margin: 0;
  font-family: var(--poppins-family);
  font-weight: 300;
  font-size: 13px;
  margin-right: 40px;
}
.conditions h4 a {
  text-decoration: none;
  color: black;
}
.conditions img {
  width: 150px;
}
.stores {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-start;
}
.stores img {
  margin-right: 10px;
  margin-top: 20px;
}

.footer-wp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.chwp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.chwp h3 {
  margin-bottom: 0.2rem;
}
.footer-wp a {
  color: rgb(35, 34, 64);
  margin-top: 2px;
}
.whatsapp-Chat-title-title{
  margin-top: 8px;
}
.whatsapp-Chat-title-subtitle{
}
@media (max-width: 1199px) {
  .cpyright-container {
    flex-wrap: wrap;
  }
  .cpyright-container div {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .child-info h5 {
    max-width: 100%;
    margin: 10px 0 0 12px;
  }

  .child-info.h5.a {
    margin-top: 10px;
  }
  .relate-link {
    justify-content: flex-start;
  }
  .footer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-info {
    align-items: center;
  }
  .info-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .footer-info {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .info-contact {
    margin-top: 0;
  }
  .child-info {
    align-items: center;
  }
  .inner-cpy-right h5{
    width: 26%;
    margin: auto;
  }
}
@media (max-width: 991px) {
  .padel-title h1 {
    font-size: 70px;
  }
}
@media (max-width: 768px) {
  .child-info h5 {
    max-width: 400px;
  }
  .padel-title h1 {
    font-size: 50px;
  }
  .inner-cpy-right h5 {
    width: 35%;
    margin: auto;
  }
}
@media (max-width: 575px) {
  .padel-title h1 {
    font-size: 40px;
  }
  .inner-footer .footer-details {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
  .inner-footer {
    margin-top: 50px;
    display: block;
  }
  .relate-link {
    justify-content: center;
    margin-top: 35px;
  }
  .child-link-container .child-link {
    align-items: center;
    justify-content: center;
  }
  .child-info h5 {
    max-width: 400px;
  }
  .child-info h5 {
    max-width: 300px;
  }
  .child-link-container .child-link h5 {
    margin: 20px 0 0 0px;
  }
  .conditions h4 {
    margin-right: 0px;
    padding: 10px 15px;
  }
  .inner-cpy-right h5 {
    width: 71%;
    margin: auto;
  }
  .social-circle {
    margin: 0;
    padding: 5px;
    margin: 10px 4px;
  }
}

@media (max-width: 425px) {
  .child-info {
    flex-direction: column;
  }
  .footer-info {
    align-items: center;
    justify-content: center;
  }
  .info-icons {
    margin: 15px 0px;
  }
  .child-info h5 {
    margin: 0;
    text-align: center;
  }
  .child-info h4 {
    margin: 0px 0 0 0px;
    padding: 0 10px;
  }
  .info-contact {
    flex-wrap: wrap;
  }
  .padel-title h1 {
    font-size: 30px;
    margin: 0;
    padding-bottom: 30px;
  }
  .whatsapp-Chat-title-title{
    text-align: center;
  }
  .whatsapp-Chat-title-subtitle{
    text-align: center;
    width: 100%;
  }
  .footer-wp{
    margin-top: 0;
  }
}
